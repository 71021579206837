import classNames from "classnames";
import Trans from "next-translate/Trans";
import React from "react";

import Button, { ButtonProps } from "components/common/Button";

import IconClose from "./icons/close.svg";
import styles from "./styles.module.scss";

interface Props {
  allowOverflow?: boolean;
  backProps?: ButtonProps & { label: string };
  buttonProps?: ButtonProps & { label: string };
  cancelProps?: ButtonProps & { label: string };
  className?: string;
  contentClassName?: string;
  description?: string;
  formClassName?: string;
  footerProps?: {
    i18nKey: string;
    onClick?: () => void;
  };
  modal?: boolean;
  onClose?: () => void;
  onSubmit?: React.FormEventHandler<HTMLFormElement>;
  title?: React.ReactNode;
  subtitle?: string;
  wide?: boolean;
  full?: boolean;
  isFullScreen?: boolean;
  danger?: boolean;
}

const AuthItem: React.FC<Props> = ({
  allowOverflow,
  backProps,
  buttonProps,
  cancelProps,
  children,
  className,
  contentClassName,
  description,
  footerProps,
  modal,
  onClose,
  onSubmit,
  title,
  subtitle,
  wide,
  full,
  isFullScreen,
  formClassName,
  danger,
}) => (
  <div
    className={classNames(styles.main, modal && styles.modal, className)}
    onClick={modal ? onClose : undefined}
    onKeyDown={() => null}
    role="button"
    tabIndex={0}
  >
    <div
      className={classNames(
        styles.authContainer,
        wide && styles.wide,
        full && styles.full,
        isFullScreen && styles.fullScreen
      )}
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      role="button"
      tabIndex={0}
    >
      <form className={classNames(formClassName)} onSubmit={onSubmit}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{title}</h2>

            {onClose && (
              <Button className={styles.close} onClick={onClose}>
                <IconClose />
              </Button>
            )}
          </div>
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>

        <div
          className={classNames(
            styles.content,
            full && styles.full,
            allowOverflow && styles.overflow,
            contentClassName
          )}
        >
          {!!description && (
            <p className={styles.description}>
              <Trans
                components={{
                  br: <br />,
                }}
                i18nKey={description}
              />
            </p>
          )}

          {children}
        </div>

        {(buttonProps || cancelProps || backProps) && (
          <div
            className={classNames(
              styles.footer,
              buttonProps && cancelProps && backProps && styles.allButtons
            )}
          >
            {cancelProps && (
              <Button
                className={styles.button}
                size="lg"
                type="light"
                {...cancelProps}
              >
                {cancelProps.label}
              </Button>
            )}

            {backProps && (
              <Button
                className={styles.button}
                size="lg"
                type="light"
                {...backProps}
              >
                {backProps.label}
              </Button>
            )}

            {buttonProps && (
              <Button
                className={styles.button}
                size="lg"
                type={danger ? "danger" : "primary"}
                loaderColor="#fff"
                {...buttonProps}
              >
                {buttonProps.label}
              </Button>
            )}
          </div>
        )}

        {footerProps && (
          <p className={styles.footerText}>
            <Trans
              i18nKey={footerProps.i18nKey}
              components={{
                button: (
                  <span
                    className={styles.button}
                    onClick={footerProps.onClick}
                    onKeyDown={() => null}
                    role="button"
                    tabIndex={0}
                  >
                    placeholder
                  </span>
                ),
              }}
            />
          </p>
        )}
      </form>
    </div>
  </div>
);

export default AuthItem;
