import classNames from "classnames";
import { useRouter } from "next/router";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import React, { useCallback, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocalStorage } from "react-use";

import Accordion from "components/common/Accordion";
import HookInput from "components/common/Input/HookInput";
import InputLabel from "components/common/Input/InputLabel";
import {
  useLazyGetCurrentUserQuery,
  useSigninMutation,
} from "services/api/user";
import { useAppDispatch, useAppSelector } from "stores";
import { defaultAppIcon } from "stores/constants";
import { selectAppIcon } from "stores/features/appBuilder/branding";
import { selectAppName } from "stores/features/appBuilder/overview";
import { selectPrivateKey } from "stores/features/project";
import {
  selectAuthMessageType,
  selectAuthModalOpened,
  selectAuthModalType,
  selectProgressiveLogin,
  uiUpdated,
} from "stores/features/ui";
import {
  selectIsAuthenticated,
  selectUser,
  selectUserCurrentOrganizationId,
} from "stores/features/user";
import { trackEvent, trackLead } from "utils/analytics";
import { ORGSECTION } from "utils/constants";
import { errorMatchesMessage, handleError } from "utils/errors";
import { useImage } from "utils/hooks";
import { StorageEmail, storageKeys } from "utils/storage";
import urls, { toRelativeUrl } from "utils/urls";

import AuthItem from "./AuthItem";
import styles from "./styles.module.scss";

interface SigninInput {
  email: string;
  password: string;
}

interface Props {
  isPage?: boolean;
}

const AuthSignin: React.FC<Props> = ({ isPage }) => {
  const [signin] = useSigninMutation();
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const appIcon = useImage(useAppSelector(selectAppIcon));
  const appName = useAppSelector(selectAppName);
  const user = useAppSelector(selectUser);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authModalType = useAppSelector(selectAuthModalType);
  const authMessageType = useAppSelector(selectAuthMessageType);
  const privateKey = useAppSelector(selectPrivateKey);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isProgressiveLogin = useAppSelector(selectProgressiveLogin);
  const authModalOpened = useAppSelector(selectAuthModalOpened);
  const [userEmail] = useLocalStorage<StorageEmail>(storageKeys.email());
  const userCurrentOrganizationId = useAppSelector(
    selectUserCurrentOrganizationId
  );
  const router = useRouter();

  const isLoginPage = router.asPath === "/login";

  const [isLoading, setIsLoading] = useState(false);

  const onBack = useMemo(() => {
    if (authMessageType.endsWith("appAccessError")) {
      return "appAccessError";
    } else if (authMessageType.endsWith("orgAccessError")) {
      return "orgAccessError";
    } else return undefined;
  }, [authMessageType]);

  const isClaimApp = authModalType.endsWith("claimApp");

  const { control, getValues, handleSubmit, setValue } = useForm<SigninInput>({
    defaultValues: { email: userEmail?.email, password: "" },
  });

  const handleSubmitInternal: SubmitHandler<SigninInput> = async (data) => {
    if (isLoading || !data.email || !data.password) {
      return;
    }
    setIsLoading(true);

    try {
      const { currentOrganizationId } = await signin({
        email: data.email,
        password: data.password,
      }).unwrap();

      if (privateKey) {
        const { data: currentUser } = await getCurrentUser();
        if (currentUser) {
          const { firstName, lastName } = currentUser;
          trackEvent("get_user", {
            email: currentUser.email,
            firstName,
            lastName,
          });
          trackLead({
            email: currentUser.email,
            firstname: firstName,
            lastname: lastName,
          });
        }
        dispatch(
          uiUpdated({ authModalType: isProgressiveLogin ? "claimApp" : null })
        );
      } else if (isLoginPage) {
        window.location.href = currentOrganizationId
          ? urls.organization(currentOrganizationId, ORGSECTION.apps)
          : urls.organizationAdd();
      } else {
        window.location.reload();
      }
    } catch (e) {
      setIsLoading(false);
      if (errorMatchesMessage(e, "invalid-credential")) {
        handleError(t("containers.auth.signinError"), { t });
        return;
      }
      handleError(e, { t });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = useCallback(() => {
    if (!isLoading) {
      dispatch(
        uiUpdated({
          authModalType: null,
          progressiveLogin: false,
          authModalOpened: null,
        })
      );
    }
  }, [dispatch, isLoading]);

  if (isAuthenticated) {
    return (
      <AuthItem
        title={t("containers.auth.alreadySignedIn")}
        modal={!isPage}
        buttonProps={
          isLoginPage && userCurrentOrganizationId
            ? {
                label: t("containers.auth.alreadySignedInButton"),
                onClick: () => {
                  window.location.href = urls.organization(
                    userCurrentOrganizationId,
                    ORGSECTION.apps
                  );
                },
              }
            : undefined
        }
      >
        <InputLabel className={styles.inputLabel} size="lg">
          <Trans
            components={{ strong: <strong /> }}
            i18nKey={t("containers.auth.youAreSignedInAs", {
              email: user.email,
            })}
          />
        </InputLabel>
      </AuthItem>
    );
  }

  return (
    <AuthItem
      buttonProps={{
        disabled: isLoading,
        isSubmit: true,
        label: t("containers.auth.signin"),
        loading: isLoading,
        onClick: () => handleSubmit(handleSubmitInternal)(),
      }}
      cancelProps={
        // eslint-disable-next-line no-nested-ternary
        isPage
          ? onBack
            ? {
                label: t("common.back"),
                onClick: () =>
                  dispatch(
                    uiUpdated({ authMessageType: `${onBack}/notLoggedIn` })
                  ),
              }
            : undefined
          : {
              label: t("common.cancel"),
              onClick: handleClose,
            }
      }
      modal={!isPage}
      onClose={isPage ? undefined : handleClose}
      onSubmit={handleSubmit(handleSubmitInternal)}
      title={
        authModalOpened === "server"
          ? t("containers.topBar.claimApp")
          : t("containers.auth.signinTitle")
      }
    >
      {authModalOpened === "server" && (
        <div className={styles.appNameContainer}>
          <img
            alt=""
            className={styles.appIconContainer}
            src={toRelativeUrl(appIcon || defaultAppIcon)!}
            height={42}
            width={42}
          />
          <span className={classNames(styles.titleAndIcon)}>
            {appName || t("containers.topBar.defaultAppName")}
          </span>
        </div>
      )}
      {isClaimApp && (
        <p className={styles.footerMessage}>
          {t("containers.auth.signinSubtitle")}
        </p>
      )}
      <p className={styles.footerMessage}>
        <Trans
          i18nKey="containers.auth.dontHaveAccount"
          components={{
            button: (
              <span
                className={styles.button}
                onClick={() => {
                  if (isPage) {
                    router.push(urls.signup, undefined, { shallow: true });
                  } else {
                    dispatch(
                      uiUpdated({
                        authModalType: isClaimApp
                          ? "signup/claimApp"
                          : "signup",
                      })
                    );
                  }
                }}
                onKeyDown={() => null}
                role="button"
                tabIndex={0}
              >
                placeholder
              </span>
            ),
          }}
        />
      </p>
      <Accordion
        className={styles.accordion}
        contentClassName={styles.content}
        disableCollapse
        modal
        title={t("containers.auth.email")}
      >
        <HookInput
          control={control}
          name="email"
          onChange={(e) => {
            setValue("email", e.value, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
          placeholder={t("containers.auth.emailPlaceholder")}
          required
          type="text"
          value={getValues("email")}
        />
      </Accordion>
      <Accordion
        className={styles.accordion}
        contentClassName={styles.content}
        disableCollapse
        modal
        title={t("containers.auth.password")}
      >
        <div className={styles.passwordContainer}>
          <HookInput
            control={control}
            name="password"
            onChange={(e) => {
              setValue("password", e.value, {
                shouldDirty: true,
                shouldValidate: true,
              });
            }}
            placeholder="**********"
            required
            type="password"
            value={getValues("password")}
          />
          <div
            className={styles.forgotPassword}
            onClick={() =>
              dispatch(
                isPage
                  ? uiUpdated({ authMessageType: "signup/resetPassword" })
                  : uiUpdated({ authModalType: "signup/resetPassword" })
              )
            }
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
          >
            {t("containers.auth.forgotPassword")}
          </div>
        </div>
      </Accordion>
    </AuthItem>
  );
};

export default AuthSignin;
